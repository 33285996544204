import React from "react";

import { Layout } from "../components/Layout";
import { Page } from "../components/Page";
import { Title } from "../components/Title";
import { Quote } from "../components/Quote";
import { Row, Column } from "../style";

export default function AnalyticsPage() {
  return (
    <Layout>
      <Page
        pageTitle="Analytics"
        heroHeadline="OUTPERFORMED DECISION-MAKING"
        heroTextline="Takiwā is a powerful analytics platform that shifts policy to where it belongs..."
        pageDescription="Takiwā Data Analytics Platform provides you with the tools to see the 'big picture' of the issues you are analysing - getting deeper, richer insights faster."
        backgroundUrl={
          "https://storage.googleapis.com/takiwa-gatsby-landing-images/hero_bg_blue_network.jpg"
        }
        backgroundPosition="240% 240%"
      >
        <Title>Analytics</Title>
        <Row>
          <Column span="4">
            <p>
              Delivered online, Takiwā Data Analytics Platform provides you with
              the tools to see the 'big picture' of the issues you are analysing
              - getting deeper, richer insights faster.
            </p>
          </Column>
          <Column span="8">
            <img
              src="https://storage.googleapis.com/takiwa-gatsby-landing-images/analytics1.png"
              alt="Takiwa analytics screenshot"
            />
          </Column>
        </Row>
        <Row>
          <Column span="4">
            <p>
              Improve the quality and speed of your organisation's
              decision-making, and ultimately your ability to affect significant
              change in your community.
            </p>
          </Column>
          <Column span="8">
            <img
              src="https://storage.googleapis.com/takiwa-gatsby-landing-images/analytics2.png"
              alt="Takiwa analytics screenshot"
            />
          </Column>
        </Row>
        <Quote>Deeper and faster analysis</Quote>
      </Page>
    </Layout>
  );
}
